{
  "home": {
    "welcomeNight": "Good Night {username}!",
    "welcomeMorning": "Good Morning {username}!",
    "welcomeDay": "Hi {username}!",
    "welcomeEvening": "Good Evening {username}!",
    "lastViewed": "Last viewed",
    "addToHomeScreen": "Add this app to your home screen for faster access and improved experience.",
    "project": {
      "importText": "Import your projects and tasks from other services into Vikunja:",
      "import": "Import your data into Vikunja"
    }
  },
  "404": {
    "title": "Not found",
    "text": "The page you requested does not exist."
  },
  "ready": {
    "loading": "Vikunja is loading…",
    "errorOccured": "An error occurred:",
    "checkApiUrl": "Please check if the api url is correct.",
    "noApiUrlConfigured": "No API url was configured. Please set one below:"
  },
  "offline": {
    "title": "You are offline.",
    "text": "Please check your network connection and try again."
  },
  "user": {
    "auth": {
      "username": "Username",
      "usernameEmail": "Username Or Email Address",
      "usernamePlaceholder": "e.g. frederick",
      "email": "Email address",
      "emailPlaceholder": "e.g. frederic{'@'}vikunja.io",
      "password": "Password",
      "passwordPlaceholder": "e.g. •••••••••••",
      "forgotPassword": "Forgot your password?",
      "resetPassword": "Reset your password",
      "resetPasswordAction": "Send me a password reset link",
      "resetPasswordSuccess": "Check your inbox! You should have an e-mail with instructions on how to reset your password.",
      "passwordsDontMatch": "Passwords don't match",
      "confirmEmailSuccess": "You successfully confirmed your email! You can log in now.",
      "totpTitle": "Two Factor Authentication Code",
      "totpPlaceholder": "e.g. 123456",
      "login": "Login",
      "createAccount": "Create account",
      "loginWith": "Log in with {provider}",
      "authenticating": "Authenticating…",
      "openIdStateError": "State does not match, refusing to continue!",
      "openIdGeneralError": "An error occured while authenticating against the third party.",
      "logout": "Logout",
      "emailInvalid": "Please enter a valid email address.",
      "usernameRequired": "Please provide a username.",
      "passwordRequired": "Please provide a password.",
      "showPassword": "Show the password",
      "hidePassword": "Hide the password",
      "noAccountYet": "Don't have an account yet?",
      "alreadyHaveAnAccount": "Already have an account?",
      "remember": "Stay logged in"
    },
    "settings": {
      "title": "Settings",
      "newPasswordTitle": "Update Your Password",
      "newPassword": "New Password",
      "newPasswordConfirm": "New Password Confirmation",
      "currentPassword": "Current Password",
      "currentPasswordPlaceholder": "Your current password",
      "passwordsDontMatch": "The new password and its confirmation don't match.",
      "passwordUpdateSuccess": "The password was successfully updated.",
      "updateEmailTitle": "Update Your Email Address",
      "updateEmailNew": "New Email Address",
      "updateEmailSuccess": "Your email address was successfully updated. We've sent you a link to confirm it.",
      "general": {
        "title": "General Settings",
        "name": "Name",
        "newName": "The new Name",
        "savedSuccess": "The settings were successfully updated.",
        "emailReminders": "Send me reminders for tasks via Email",
        "overdueReminders": "Send me a summary of my undone overdue tasks every day",
        "discoverableByName": "Allow other users to add me as a member to teams or projects when they search for my name",
        "discoverableByEmail": "Allow other users to add me as a member to teams or projects when they search for my full email",
        "playSoundWhenDone": "Play a sound when marking tasks as done",
        "weekStart": "Week starts on",
        "weekStartSunday": "Sunday",
        "weekStartMonday": "Monday",
        "language": "Language",
        "defaultProject": "Default Project",
        "timezone": "Time Zone",
        "overdueTasksRemindersTime": "Overdue tasks reminder email time",
        "filterUsedOnOverview": "Saved filter used on the overview page"
      },
      "totp": {
        "title": "Two Factor Authentication",
        "enroll": "Enroll",
        "finishSetupPart1": "To finish your setup, use this secret in your totp app (Google Authenticator or similar):",
        "finishSetupPart2": "After that, enter a code from your app below.",
        "scanQR": "Alternatively you can scan this QR code:",
        "passcode": "Passcode",
        "passcodePlaceholder": "A code generated by your totp application",
        "setupSuccess": "You've successfully set up two factor authentication!",
        "enterPassword": "Please Enter Your Password",
        "disable": "Disable two factor authentication",
        "confirmSuccess": "You've successfully confirmed your totp setup and can use it from now on!",
        "disableSuccess": "Two factor authentication was successfully disabled."
      },
      "caldav": {
        "title": "CalDAV",
        "howTo": "You can connect Vikunja to CalDAV clients to view and manage all tasks from different clients. Enter this url into your client:",
        "more": "More information about CalDAV in Vikunja",
        "tokens": "CalDAV Tokens",
        "tokensHowTo": "You can use a CalDAV token to use instead of a password to log in the above endpoint.",
        "createToken": "Create a token",
        "tokenCreated": "Here is your token: {token}",
        "wontSeeItAgain": "Write it down, you won't be able to see it again.",
        "mustUseToken": "You need to create a CalDAV token if you want to use CalDAV with a third party client. Use the token as the password.",
        "usernameIs": "Your username is: {0}"
      },
      "avatar": {
        "title": "Avatar",
        "initials": "Initials",
        "gravatar": "Gravatar",
        "marble": "Marble",
        "upload": "Upload",
        "uploadAvatar": "Upload Avatar",
        "statusUpdateSuccess": "Avatar status was updated successfully!",
        "setSuccess": "The avatar has been set successfully!"
      },
      "quickAddMagic": {
        "title": "Quick Add Magic Mode",
        "disabled": "Disabled",
        "todoist": "Todoist",
        "vikunja": "Vikunja"
      },
      "appearance": {
        "title": "Color Scheme",
        "setSuccess": "Saved change of color scheme to {colorScheme}",
        "colorScheme": {
          "light": "Light",
          "system": "System",
          "dark": "Dark"
        }
      }
    },
    "deletion": {
      "title": "Delete your Vikunja Account",
      "text1": "The deletion of your account is permanent and cannot be undone. We will delete all your projects, tasks and everything associated with it.",
      "text2": "To proceed, please enter your password. You will receive an email with further instructions.",
      "confirm": "Delete my account",
      "requestSuccess": "The request was successful. You'll receive an email with further instructions.",
      "passwordRequired": "Please enter your password.",
      "confirmSuccess": "You've successfully confirmed the deletion of your account. We will delete your account in three days.",
      "scheduled": "We will delete your Vikunja account at {date} ({dateSince}).",
      "scheduledCancel": "To cancel the deletion of your account, click here.",
      "scheduledCancelText": "To cancel the deletion of your account, please enter your password below:",
      "scheduledCancelConfirm": "Cancel the deletion of my account",
      "scheduledCancelSuccess": "We will not delete your account."
    },
    "export": {
      "title": "Export your Vikunja data",
      "description": "You can request a copy of all your Vikunja data. This includes Projects, Tasks and everything associated to them. You can import this data in any Vikunja instance through the migration function.",
      "descriptionPasswordRequired": "Please enter your password to proceed:",
      "request": "Request a copy of my Vikunja Data",
      "success": "You've successfully requested your Vikunja Data! We will send you an email once it's ready to download.",
      "downloadTitle": "Download your exported Vikunja data"
    }
  },
  "project": {
    "archivedMessage": "This project is archived. It is not possible to create new or edit tasks for it.",
    "archived": "Archived",
    "showArchived": "Show Archived",
    "title": "Project Title",
    "color": "Color",
    "projects": "Projects",
    "parent": "Parent Project",
    "search": "Type to search for a project…",
    "searchSelect": "Click or press enter to select this project",
    "shared": "Shared Projects",
    "noDescriptionAvailable": "No project description is available.",
    "inboxTitle": "Inbox",
    "create": {
      "header": "New project",
      "titlePlaceholder": "The project's title goes here…",
      "addTitleRequired": "Please specify a title.",
      "createdSuccess": "The project was successfully created.",
      "addProjectRequired": "Please specify a project or set a default project in the settings."
    },
    "archive": {
      "title": "Archive \"{project}\"",
      "archive": "Archive this project",
      "unarchive": "Un-Archive this project",
      "unarchiveText": "You will be able to create new tasks or edit it.",
      "archiveText": "You won't be able to edit this project or create new tasks until you un-archive it.",
      "success": "The project was successfully archived."
    },
    "background": {
      "title": "Set project background",
      "remove": "Remove Background",
      "upload": "Choose a background from your pc",
      "searchPlaceholder": "Search for a background…",
      "poweredByUnsplash": "Powered by Unsplash",
      "loadMore": "Load more photos",
      "success": "The background has been set successfully!",
      "removeSuccess": "The background has been removed successfully!"
    },
    "delete": {
      "title": "Delete \"{project}\"",
      "header": "Delete this project",
      "text1": "Are you sure you want to delete this project and all of its contents?",
      "text2": "This includes all tasks and CANNOT BE UNDONE!",
      "success": "The project was successfully deleted.",
      "tasksToDelete": "This will irrevocably remove approx. {count} tasks.",
      "noTasksToDelete": "This project does not contain any tasks, it should be safe to delete."
    },
    "duplicate": {
      "title": "Duplicate this project",
      "label": "Duplicate",
      "text": "Select a parent project which should hold the duplicated project:",
      "success": "The project was successfully duplicated."
    },
    "edit": {
      "header": "Edit This Project",
      "title": "Edit \"{project}\"",
      "titlePlaceholder": "The project title goes here…",
      "identifierTooltip": "The project identifier can be used to uniquely identify a task across projects. You can set it to empty to disable it.",
      "identifier": "Project Identifier",
      "identifierPlaceholder": "The project identifier goes here…",
      "description": "Description",
      "descriptionPlaceholder": "The projects description goes here…",
      "color": "Color",
      "success": "The project was successfully updated."
    },
    "share": {
      "header": "Share this project",
      "title": "Share \"{project}\"",
      "share": "Share",
      "links": {
        "title": "Share Links",
        "what": "What is a share link?",
        "explanation": "Share Links allow you to easily share a project with other users who don't have an account on Vikunja.",
        "create": "Create a new link share",
        "name": "Name (optional)",
        "namePlaceholder": "e.g. Lorem Ipsum",
        "nameExplanation": "All actions done by this link share will show up with the name.",
        "password": "Password (optional)",
        "passwordExplanation": "When signing in, the user will be required to enter this password.",
        "noName": "No name set",
        "remove": "Remove a link share",
        "removeText": "Are you sure you want to remove this link share? It will no longer be possible to access this project with this link share. This cannot be undone!",
        "createSuccess": "The link share was successfully created.",
        "deleteSuccess": "The link share was successfully deleted",
        "view": "View",
        "sharedBy": "Shared by {0}"
      },
      "userTeam": {
        "typeUser": "user | users",
        "typeTeam": "team | teams",
        "shared": "Shared with these {type}",
        "you": "You",
        "notShared": "Not shared with any {type} yet.",
        "removeHeader": "Remove a {type} from the {sharable}",
        "removeText": "Are you sure you want to remove this {sharable} from the {type}? This cannot be undone!",
        "removeSuccess": "The {sharable} was successfully removed from the {type}.",
        "addedSuccess": "The {type} was successfully added.",
        "updatedSuccess": "The {type} was successfully added."
      },
      "right": {
        "title": "Permission",
        "read": "Read only",
        "readWrite": "Read & write",
        "admin": "Admin"
      },
      "attributes": {
        "link": "Link",
        "delete": "Delete"
      }
    },
    "list": {
      "title": "List",
      "add": "Add",
      "addPlaceholder": "Add a new task…",
      "empty": "This project is currently empty.",
      "newTaskCta": "Create a new task.",
      "editTask": "Edit Task"
    },
    "gantt": {
      "title": "Gantt",
      "showTasksWithoutDates": "Show tasks which don't have dates set",
      "size": "Size",
      "default": "Default",
      "month": "Month",
      "day": "Day",
      "hour": "Hour",
      "range": "Date Range",
      "noDates": "This task has no dates set."
    },
    "table": {
      "title": "Table",
      "columns": "Columns"
    },
    "kanban": {
      "title": "Kanban",
      "limit": "Limit: {limit}",
      "noLimit": "Not Set",
      "doneBucket": "Done bucket",
      "doneBucketHint": "All tasks moved into this bucket will automatically marked as done.",
      "doneBucketHintExtended": "All tasks moved into the done bucket will be marked as done automatically. All tasks marked as done from elsewhere will be moved as well.",
      "doneBucketSavedSuccess": "The done bucket has been saved successfully.",
      "deleteLast": "You cannot remove the last bucket.",
      "addTaskPlaceholder": "Enter the new task title…",
      "addTask": "Add a task",
      "addAnotherTask": "Add another task",
      "addBucket": "Create a new bucket",
      "addBucketPlaceholder": "Enter the new bucket title…",
      "deleteHeaderBucket": "Delete the bucket",
      "deleteBucketText1": "Are you sure you want to delete this bucket?",
      "deleteBucketText2": "This will not delete any tasks but move them into the default bucket.",
      "deleteBucketSuccess": "The bucket has been deleted successfully.",
      "bucketTitleSavedSuccess": "The bucket title has been saved successfully.",
      "bucketLimitSavedSuccess": "The bucket limit been saved successfully.",
      "collapse": "Collapse this bucket"
    },
    "pseudo": {
      "favorites": {
        "title": "Favorites"
      }
    }
  },
  "filters": {
    "title": "Filters",
    "clear": "Clear Filters",
    "attributes": {
      "title": "Title",
      "titlePlaceholder": "The saved filter title goes here…",
      "description": "Description",
      "descriptionPlaceholder": "The description goes here…",
      "includeNulls": "Include Tasks which don't have a value set",
      "requireAll": "Require all filters to be true for a task to show up",
      "showDoneTasks": "Show Done Tasks",
      "sortAlphabetically": "Sort Alphabetically",
      "enablePriority": "Enable Filter By Priority",
      "enablePercentDone": "Enable Filter By Progress",
      "dueDateRange": "Due Date Range",
      "startDateRange": "Start Date Range",
      "endDateRange": "End Date Range",
      "reminderRange": "Reminder Date Range"
    },
    "create": {
      "title": "New Saved Filter",
      "description": "A saved filter is a virtual project which is computed from a set of filters each time it is accessed.",
      "action": "Create new saved filter",
      "titleRequired": "Please provide a title for the filter."
    },
    "delete": {
      "header": "Delete this saved filter",
      "text": "Are you sure you want to delete this saved filter?",
      "success": "The filter was deleted successfully."
    },
    "edit": {
      "title": "Edit This Saved Filter",
      "success": "The filter was saved successfully."
    }
  },
  "migrate": {
    "title": "Import from other services",
    "titleService": "Import your data from {name} into Vikunja",
    "import": "Import your data into Vikunja",
    "description": "Click on the logo of one of the third-party services below to get started.",
    "descriptionDo": "Vikunja will import all lists, tasks, notes, reminders and files you have access to.",
    "authorize": "To authorize Vikunja to access your {name} Account, click the button below.",
    "getStarted": "Get Started",
    "inProgress": "Importing in progress…",
    "alreadyMigrated1": "It looks like you've already imported your stuff from {name} at {date}.",
    "alreadyMigrated2": "Importing again is possible, but might create duplicates. Are you sure?",
    "confirm": "I am sure, please start migrating now!",
    "importUpload": "To import data from {name} into Vikunja, click the button below to select a file.",
    "upload": "Upload file"
  },
  "label": {
    "title": "Labels",
    "manage": "Manage labels",
    "description": "Click on a label to edit it. You can edit all labels you created, you can use all labels which are associated with a task to whose project you have access.",
    "newCTA": "You currently do not have any labels.",
    "search": "Type to search for a label…",
    "create": {
      "header": "New label",
      "title": "Create a new label",
      "titleRequired": "Please specify a title.",
      "success": "The label was successfully created."
    },
    "edit": {
      "header": "Edit Label",
      "forbidden": "You are not allowed to edit this label because you don't own it.",
      "success": "The label was successfully updated."
    },
    "deleteSuccess": "The label was successfully deleted.",
    "attributes": {
      "title": "Title",
      "titlePlaceholder": "The label title goes here…",
      "description": "Description",
      "descriptionPlaceholder": "Label description",
      "color": "Color"
    }
  },
  "sharing": {
    "authenticating": "Authenticating…",
    "passwordRequired": "This shared project requires a password. Please enter it below:",
    "error": "An error occured.",
    "invalidPassword": "The password is invalid."
  },
  "navigation": {
    "overview": "Overview",
    "upcoming": "Upcoming",
    "settings": "Settings",
    "imprint": "Imprint",
    "privacy": "Privacy Policy"
  },
  "misc": {
    "loading": "Loading…",
    "save": "Save",
    "delete": "Delete",
    "confirm": "Confirm",
    "cancel": "Cancel",
    "refresh": "Refresh",
    "disable": "Disable",
    "copy": "Copy to clipboard",
    "copyError": "Copy to clipboard failed",
    "search": "Search",
    "searchPlaceholder": "Type to search…",
    "previous": "Previous",
    "next": "Next",
    "poweredBy": "Powered by Vikunja",
    "info": "Info",
    "create": "Create",
    "doit": "Do it!",
    "saving": "Saving…",
    "saved": "Saved!",
    "default": "Default",
    "close": "Close",
    "download": "Download",
    "showMenu": "Show the menu",
    "hideMenu": "Hide the menu",
    "forExample": "For example:",
    "welcomeBack": "Welcome Back!",
    "custom": "Custom",
    "id": "ID",
    "created": "Created at",
    "actions": "Actions",
    "cannotBeUndone": "This cannot be undone!"
  },
  "input": {
    "resetColor": "Reset Color",
    "datepicker": {
      "today": "Today",
      "tomorrow": "Tomorrow",
      "nextMonday": "Next Monday",
      "thisWeekend": "This Weekend",
      "laterThisWeek": "Later This Week",
      "nextWeek": "Next Week",
      "chooseDate": "Choose a date"
    },
    "editor": {
      "edit": "Edit",
      "done": "Done",
      "heading1": "Heading 1",
      "heading2": "Heading 2",
      "heading3": "Heading 3",
      "headingSmaller": "Heading Smaller",
      "headingBigger": "Heading Bigger",
      "bold": "Bold",
      "italic": "Italic",
      "strikethrough": "Strikethrough",
      "code": "Code",
      "quote": "Quote",
      "unorderedList": "Unordered List",
      "orderedList": "Ordered List",
      "cleanBlock": "Clean Block",
      "link": "Link",
      "image": "Image",
      "table": "Table",
      "horizontalRule": "Horizontal Rule",
      "sideBySide": "Side By Side",
      "guide": "Guide"
    },
    "multiselect": {
      "createPlaceholder": "Create new",
      "selectPlaceholder": "Click or press enter to select"
    },
    "datepickerRange": {
      "to": "To",
      "from": "From",
      "fromto": "{from} to {to}",
      "ranges": {
        "today": "Today",
        
        "thisWeek": "This Week",
        "restOfThisWeek": "The Rest of This Week",
        "nextWeek": "Next Week",
        "next7Days": "Next 7 Days",
        "lastWeek": "Last Week",
        
        "thisMonth": "This Month",
        "restOfThisMonth": "The Rest of This Month",
        "nextMonth": "Next Month",
        "next30Days": "Next 30 Days",
        "lastMonth": "Last Month",
        
        "thisYear": "This Year",
        "restOfThisYear": "The Rest of This Year"
      }
    },
    "datemathHelp": {
      "canuse": "You can use date math to filter for relative dates.",
      "learnhow": "Check out how it works",
      "title": "Date Math",
      "intro": "Specify relative dates which are resolved on the fly by Vikunja when applying the filter.",
      "expression": "Each Date Math expression starts with an anchor date, which can either be {0}, or a date string ending with {1}. This anchor date can optionally be followed by one or more maths expressions.",
      "similar": "These expressions are similar to the ones provided by {0} and {1}.",
      "add1Day": "Add one day",
      "minus1Day": "Subtract one day",
      "roundDay": "Round down to the nearest day",
      "supportedUnits": "Supported time units",
      "someExamples": "Examples of time expressions",
      "units": {
        "seconds": "Seconds",
        "minutes": "Minutes",
        "hours": "Hours",
        "days": "Days",
        "weeks": "Weeks",
        "months": "Months",
        "years": "Years"
      },
      "examples": {
        "now": "Right now",
        "in24h": "In 24h",
        "today": "Today at 00:00",
        "beginningOfThisWeek": "The beginning of this week at 00:00",
        "endOfThisWeek": "The end of this week",
        "in30Days": "In 30 days",
        "datePlusMonth": "{0} plus one month at 00:00 of that day"
      }
    }
  },
  "task": {
    "task": "Task",
    "new": "Create a new task",
    "delete": "Delete this task",
    "createSuccess": "The task was successfully created.",
    "addReminder": "Add a new reminder…",
    "doneSuccess": "The task was successfully marked as done.",
    "undoneSuccess": "The task was successfully un-marked as done.",
    "undo": "Undo",
    "openDetail": "Open task detail view",
    "checklistTotal": "{checked} of {total} tasks",
    "checklistAllDone": "{total} tasks",
    "show": {
      "titleCurrent": "Current Tasks",
      "titleDates": "Tasks from {from} until {to}",
      "noDates": "Show tasks without dates",
      "overdue": "Show overdue tasks",
      "fromuntil": "Tasks from {from} until {until}",
      "select": "Select a date range",
      "noTasks": "Nothing to do — Have a nice day!"
    },
    "detail": {
      "chooseDueDate": "Click here to set a due date",
      "chooseStartDate": "Click here to set a start date",
      "chooseEndDate": "Click here to set an end date",
      "move": "Move task to a different project",
      "done": "Mark task done!",
      "undone": "Mark as undone",
      "created": "Created {0} by {1}",
      "updated": "Updated {0}",
      "doneAt": "Done {0}",
      "updateSuccess": "The task was saved successfully.",
      "deleteSuccess": "The task has been deleted successfully.",
      "belongsToProject": "This task belongs to project '{project}'",
      "due": "Due {at}",
      "closePopup": "Close popup",
      "delete": {
        "header": "Delete this task",
        "text1": "Are you sure you want to remove this task?",
        "text2": "This will also remove all attachments, reminders and relations associated with this task and cannot be undone!"
      },
      "actions": {
        "assign": "Assign to User",
        "label": "Add Labels",
        "priority": "Set Priority",
        "dueDate": "Set Due Date",
        "startDate": "Set Start Date",
        "endDate": "Set End Date",
        "reminders": "Set Reminders",
        "repeatAfter": "Set Repeating Interval",
        "percentDone": "Set Progress",
        "attachments": "Add Attachments",
        "relatedTasks": "Add Relation",
        "moveProject": "Move",
        "color": "Set Color",
        "delete": "Delete",
        "favorite": "Add to Favorites",
        "unfavorite": "Remove from Favorites"
      }
    },
    "attributes": {
      "assignees": "Assignees",
      "color": "Color",
      "created": "Created",
      "createdBy": "Created By",
      "description": "Description",
      "done": "Done",
      "dueDate": "Due Date",
      "endDate": "End Date",
      "labels": "Labels",
      "percentDone": "Progress",
      "priority": "Priority",
      "relatedTasks": "Related Tasks",
      "reminders": "Reminders",
      "repeat": "Repeat",
      "startDate": "Start Date",
      "title": "Title",
      "updated": "Updated"
    },
    "subscription": {
      "subscribedTaskThroughParentProject": "You can't unsubscribe here because you are subscribed to this task through its project.",
      "subscribedProject": "You are currently subscribed to this project and will receive notifications for changes.",
      "notSubscribedProject": "You are not subscribed to this project and won't receive notifications for changes.",
      "subscribedTask": "You are currently subscribed to this task and will receive notifications for changes.",
      "notSubscribedTask": "You are not subscribed to this task and won't receive notifications for changes.",
      "subscribe": "Subscribe",
      "unsubscribe": "Unsubscribe",
      "subscribeSuccessProject": "You are now subscribed to this project",
      "unsubscribeSuccessProject": "You are now unsubscribed to this project",
      "subscribeSuccessTask": "You are now subscribed to this task",
      "unsubscribeSuccessTask": "You are now unsubscribed to this task"
    },
    "attachment": {
      "title": "Attachments",
      "createdBy": "created {0} by {1}",
      "downloadTooltip": "Download this attachment",
      "upload": "Upload attachment",
      "drop": "Drop files here to upload",
      "delete": "Delete attachment",
      "deleteTooltip": "Delete this attachment",
      "deleteText1": "Are you sure you want to delete the attachment {filename}?",
      "copyUrl": "Copy URL",
      "copyUrlTooltip": "Copy the url of this attachment for usage in text",
      "setAsCover": "Make cover",
      "unsetAsCover": "Remove cover",
      "successfullyChangedCoverImage": "The cover image was successfully changed.",
      "usedAsCover": "Cover image"
    },
    "comment": {
      "title": "Comments",
      "loading": "Loading comments…",
      "edited": "edited {date}",
      "creating": "Creating comment…",
      "placeholder": "Add your comment…",
      "comment": "Comment",
      "delete": "Delete this comment",
      "deleteText1": "Are you sure you want to delete this comment?",
      "deleteSuccess": "The comment was deleted successfully.",
      "addedSuccess": "The comment was added successfully."
    },
    "deferDueDate": {
      "title": "Defer due date",
      "1day": "1 day",
      "3days": "3 days",
      "1week": "1 week"
    },
    "description": {
      "placeholder": "Click here to enter a description…",
      "empty": "No description available yet."
    },
    "assignee": {
      "placeholder": "Type to assign a user…",
      "selectPlaceholder": "Assign this user",
      "assignSuccess": "The user has been assigned successfully.",
      "unassignSuccess": "The user has been unassigned successfully."
    },
    "label": {
      "placeholder": "Type to add a new label…",
      "createPlaceholder": "Add this as new label",
      "addSuccess": "The label has been added successfully.",
      "createSuccess": "The label has been created successfully.",
      "removeSuccess": "The label has been removed successfully.",
      "addCreateSuccess": "The label has been created and added successfully.",
      "delete": {
        "header": "Delete this label",
        "text1": "Are you sure you want to delete this label?",
        "text2": "This will remove it from all tasks and cannot be restored."
      }
    },
    "priority": {
      "unset": "Unset",
      "low": "Low",
      "medium": "Medium",
      "high": "High",
      "urgent": "Urgent",
      "doNow": "DO NOW"
    },
    "relation": {
      "add": "Add a New Task Relation",
      "new": "New Task Relation",
      "searchPlaceholder": "Type search for a new task to add as related…",
      "createPlaceholder": "Add this as new related task",
      "differentProject": "This task belongs to a different project.",
      "noneYet": "No task relations yet.",
      "delete": "Delete Task Relation",
      "deleteText1": "Are you sure you want to delete this task relation?",
      "select": "Select a relation kind",
      "taskRequired": "Please select a task or enter a new task title.",
      "kinds": {
        "subtask": "Subtask | Subtasks",
        "parenttask": "Parent Task | Parent Tasks",
        "related": "Related Task | Related Tasks",
        "duplicateof": "Duplicate Of | Duplicates Of",
        "duplicates": "Duplicates | Duplicates",
        "blocking": "Blocking | Blocking",
        "blocked": "Blocked By | Blocked By",
        "precedes": "Precedes | Precedes",
        "follows": "Follows | Follows",
        "copiedfrom": "Copied From | Copied From",
        "copiedto": "Copied To | Copied To"
      }
    },
    "reminder": {
      "before": "{amount} {unit} before {type}",
      "after": "{amount} {unit} after {type}",
      "beforeShort": "before",
      "afterShort": "after",
      "onDueDate": "On the due date",
      "onStartDate": "On the start date",
      "onEndDate": "On the end date",
      "custom": "Custom",
      "dateAndTime": "Date and time"
    },
    "repeat": {
      "everyDay": "Every Day",
      "everyWeek": "Every Week",
      "everyMonth": "Every Month",
      "mode": "Repeat mode",
      "monthly": "Monthly",
      "fromCurrentDate": "From Current Date",
      "each": "Each",
      "specifyAmount": "Specify an amount…",
      "hours": "Hours",
      "days": "Days",
      "weeks": "Weeks",
      "months": "Months",
      "years": "Years",
      "invalidAmount": "Please enter more than 0."
    },
    "quickAddMagic": {
      "hint": "Use magic prefixes to define due dates, assignees and other task properties.",
      "title": "Quick Add Magic",
      "intro": "When creating a task, you can use special keywords to directly add attributes to the newly created task. This allows to add commonly used attributes to tasks much faster.",
      "multiple": "You can use this multiple times.",
      "label1": "To add a label, simply prefix the name of the label with {prefix}.",
      "label2": "Vikunja will first check if the label already exist and create it if not.",
      "label3": "To use spaces, simply add a \" or ' around the label name.",
      "label4": "For example: {prefix}\"Label with spaces\".",
      "priority1": "To set a task's priority, add a number 1-5, prefixed with a {prefix}.",
      "priority2": "The higher the number, the higher the priority.",
      "assignees": "To directly assign the task to a user, add their username prefixed with {prefix} to the task.",
      "project1": "To set a project for the task to appear in, enter its name prefixed with {prefix}.",
      "project2": "This will return an error if the project does not exist.",
      "project3": "To use spaces, simply add a \" or ' around the project name.",
      "project4": "For example: {prefix}\"Project with spaces\".",
      "dateAndTime": "Date and time",
      "date": "Any date will be used as the due date of the new task. You can use dates in any of these formats:",
      "dateWeekday": "any weekday, will use the next date with that date",
      "dateCurrentYear": "will use the current year",
      "dateNth": "will use the {day}th of the current month",
      "dateTime": "Combine any of the date formats with \"{time}\" (or {timePM}) to set a time.",
      "repeats": "Repeating tasks",
      "repeatsDescription": "To set a task as repeating in an interval, simply add '{suffix}' to the task text. The amount needs to be a number and can be omitted to use just the type (see examples)."
    }
  },
  "team": {
    "title": "Teams",
    "noTeams": "You are currently not part of any teams.",
    "create": {
      "title": "Create a new team",
      "success": "The team was successfully created."
    },
    "edit": {
      "title": "Edit Team \"{team}\"",
      "members": "Team Members",
      "search": "Type to search a user…",
      "addUser": "Add to team",
      "makeMember": "Make Member",
      "makeAdmin": "Make Admin",
      "success": "The team was successfully updated.",
      "userAddedSuccess": "The team member was successfully added.",
      "madeMember": "The team member was successfully made member.",
      "madeAdmin": "The team member was successfully made admin.",
      "mustSelectUser": "Please select a user.",
      "delete": {
        "header": "Delete the team",
        "text1": "Are you sure you want to delete this team and all of its members?",
        "text2": "All team members will lose access to projects shared with this team. This CANNOT BE UNDONE!",
        "success": "The team was successfully deleted."
      },
      "deleteUser": {
        "header": "Remove a user from the team",
        "text1": "Are you sure you want to remove this user from the team?",
        "text2": "They will lose access to all projects this team has access to. This CANNOT BE UNDONE!",
        "success": "The user was successfully deleted from the team."
      },
      "leave": {
        "title": "Leave team",
        "text1": "Are you sure you want to leave this team?",
        "text2": "You will lose access to all projects this team has access to. If you change your mind you'll need a team admin to add you again.",
        "success": "You have successfully left the team."
      }
    },
    "attributes": {
      "name": "Team Name",
      "namePlaceholder": "The team's name goes here…",
      "nameRequired": "Please specify a name.",
      "description": "Description",
      "descriptionPlaceholder": "The teams description goes here…",
      "admin": "Admin",
      "member": "Member"
    }
  },
  "keyboardShortcuts": {
    "title": "Keyboard Shortcuts",
    "general": "General",
    "allPages": "These shortcuts work on all pages.",
    "currentPageOnly": "These shortcuts work only on the current page.",
    "somePagesOnly": "These shortcuts work only on some pages.",
    "toggleMenu": "Toggle The Menu",
    "quickSearch": "Open the search/quick action bar",
    "then": "then",
    "task": {
      "title": "Task Page",
      "done": "Mark task done / undone",
      "assign": "Assign this task to a user",
      "labels": "Add labels to this task",
      "dueDate": "Change the due date of this task",
      "attachment": "Add an attachment to this task",
      "related": "Modify related tasks of this task",
      "color": "Change the color of this task",
      "move": "Move this task to another project",
      "reminder": "Manage reminders of this task",
      "description": "Toggle editing of the task description",
      "delete": "Delete this task",
      "priority": "Change the priority of this task",
      "favorite": "Mark this task as favorite / unfavorite"
    },
    "project": {
      "title": "Project Views",
      "switchToListView": "Switch to list view",
      "switchToGanttView": "Switch to gantt view",
      "switchToKanbanView": "Switch to kanban view",
      "switchToTableView": "Switch to table view"
    },
    "navigation": {
      "title": "Navigation",
      "overview": "Navigate to overview",
      "upcoming": "Navigate to upcoming tasks",
      "labels": "Navigate to labels",
      "teams": "Navigate to teams",
      "projects": "Navigate to projects"
    }
  },
  "update": {
    "available": "There is an update available!",
    "do": "Update Now"
  },
  "menu": {
    "edit": "Edit",
    "archive": "Archive",
    "duplicate": "Duplicate",
    "delete": "Delete",
    "unarchive": "Un-Archive",
    "setBackground": "Set background",
    "share": "Share",
    "newProject": "New project",
    "createProject": "Create project"
  },
  "apiConfig": {
    "url": "Vikunja URL",
    "urlPlaceholder": "eg. https://localhost:3456",
    "change": "change",
    "use": "Using Vikunja installation at {0}",
    "error": "Could not find or use Vikunja installation at \"{domain}\". Please try a different url.",
    "success": "Using Vikunja installation at \"{domain}\".",
    "urlRequired": "A url is required."
  },
  "loadingError": {
    "failed": "Loading failed, please {0}. If the error persists, please {1}.",
    "tryAgain": "try again",
    "contact": "contact us"
  },
  "notification": {
    "title": "Notifications",
    "none": "You don't have any notifications. Have a nice day!",
    "explainer": "Notifications will appear here when actions projects or tasks you subscribed to happen."
  },
  "quickActions": {
    "commands": "Commands",
    "placeholder": "Type a command or search…",
    "hint": "You can use {project} to limit the search to a project. Combine {project} or {label} (labels) with a search query to search for a task with these labels or on that project. Use {assignee} to only search for teams.",
    "tasks": "Tasks",
    "projects": "Projects",
    "teams": "Teams",
    "newProject": "Enter the title of the new project…",
    "newTask": "Enter the title of the new task…",
    "newTeam": "Enter the name of the new team…",
    "createTask": "Create a task in the current project ({title})",
    "createProject": "Create a project",
    "cmds": {
      "newTask": "New task",
      "newProject": "New project",
      "newTeam": "New team"
    }
  },
  "date": {
    "locale": "en",
    "altFormatLong": "j M Y H:i",
    "altFormatShort": "j M Y"
  },
  "error": {
    "error": "Error",
    "success": "Success",
    "0001": "You're not allowed to do that.",
    "1001": "A user with this username already exists.",
    "1002": "A user with this email address already exists.",
    "1004": "No username and password specified.",
    "1005": "The user does not exist.",
    "1006": "Could not get the user id.",
    "1008": "No password reset token provided.",
    "1009": "Invalid password reset token.",
    "1010": "Invalid email confirm token.",
    "1011": "Wrong username or password.",
    "1012": "Email address of the user not confirmed.",
    "1013": "New password is empty.",
    "1014": "Old password is empty.",
    "1015": "Totp is already enabled for this user.",
    "1016": "Totp is not enabled for this user.",
    "1017": "The totp passcode is invalid.",
    "1018": "The user avatar type setting is invalid.",
    "2001": "ID cannot be empty or 0.",
    "2002": "Some of the request data was invalid.",
    "3001": "The project does not exist.",
    "3004": "You need to have read permissions on that project to perform that action.",
    "3005": "The project title cannot be empty.",
    "3006": "The project share does not exist.",
    "3007": "A project with this identifier already exists.",
    "3008": "The project is archived and can therefore only be accessed read only. This is also true for all tasks associated with this project.",
    "4001": "The project task text cannot be empty.",
    "4002": "The project task does not exist.",
    "4003": "All bulk editing tasks must belong to the same project.",
    "4004": "Need at least one task when bulk editing tasks.",
    "4005": "You do not have the right to see the task.",
    "4006": "You can't set a parent task as the task itself.",
    "4007": "You can't create a task relation with an invalid kind of relation.",
    "4008": "You can't create a task relation which already exists.",
    "4009": "The task relation does not exist.",
    "4010": "Cannot relate a task with itself.",
    "4011": "The task attachment does not exist.",
    "4012": "The task attachment is too large.",
    "4013": "The task sort param is invalid.",
    "4014": "The task sort order is invalid.",
    "4015": "The task comment does not exist.",
    "4016": "Invalid task field.",
    "4017": "Invalid task filter comparator.",
    "4018": "Invalid task filter concatenator.",
    "4019": "Invalid task filter value.",
    "6001": "The team name cannot be empty.",
    "6002": "The team does not exist.",
    "6004": "The team already has access to that project.",
    "6005": "The user is already a member of that team.",
    "6006": "Cannot delete the last team member.",
    "6007": "The team does not have access to the project to perform that action.",
    "7002": "The user already has access to that project.",
    "7003": "You do not have access to that project.",
    "8001": "This label already exists on that task.",
    "8002": "The label does not exist.",
    "8003": "You do not have access to this label.",
    "9001": "The right is invalid.",
    "10001": "The bucket does not exist.",
    "10002": "The bucket does not belong to that project.",
    "10003": "You cannot remove the last bucket on a project.",
    "10004": "You cannot add the task to this bucket as it already exceeded the limit of tasks it can hold.",
    "10005": "There can be only one done bucket per project.",
    "11001": "The saved filter does not exist.",
    "11002": "Saved filters are not available for link shares.",
    "12001": "The subscription entity type is invalid.",
    "12002": "You are already subscribed to the entity itself or a parent entity.",
    "13001": "This link share requires a password for authentication, but none was provided.",
    "13002": "The provided link share password was invalid."
  },
  "about": {
    "title": "About",
    "frontendVersion": "Frontend Version: {version}",
    "apiVersion": "API Version: {version}"
  },
  "time": {
    "units": {
      "seconds": "second|seconds",
      "minutes": "minute|minutes",
      "hours": "hour|hours",
      "days": "day|days",
      "weeks": "week|weeks",
      "months": "month|months",
      "years": "year|years"
    }
  }
}